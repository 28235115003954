<template>
    <div class="grid grid__layout">
        <SearchInput
            style="border: 1px solid #e4e4e4"
            placeholder="Front End Developer"
            v-model="searchValue"
            class="col-4"
        />
        <Dropdown
            class="col-3"
            style="border: 1px solid #e4e4e4"
            :options="[]"
            placeholder="Job Title"
        />
        <Dropdown
            class="col-3"
            style="border: 1px solid #e4e4e4"
            :options="[]"
            placeholder="City"
        />
        <Button class="col-2" theme="purple">Search</Button>
    </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import Dropdown from "@/components/Dropdown";
import Button from "@/components/Button";

export default {
    name: "FilterHeader",
    components: {
        SearchInput,
        Dropdown,
        Button,
    },
    data() {
        return {
            searchValue: "",
        };
    },
};
</script>

<style scoped></style>
