<template>
    <div class="u-relative">
        <label
            :for="id"
            class="range__thumb u-absolute flex flex__center flex__item-center cursor-pointer"
            :style="`--thumb-position: ${thumbPosition}`"
        >
            <span class="text-blue">{{ range }}</span>
        </label>
        <input
            type="range"
            class="range__slider"
            :id="id"
            :min="min"
            :max="max"
            v-model="range"
        />
        <p class="flex flex__spaced mt-2 fw-600">
            <span class="fs-14">{{ startText }}</span>
            <span class="fs-14">{{ endText }}</span>
        </p>
    </div>
</template>

<script>
export default {
    name: "Range",
    props: {
        id: {
            type: String,
            default: "",
        },
        startText: {
            type: String,
            default: "Start text",
        },
        endText: {
            type: String,
            default: "End text",
        },
        min: {
            type: [String, Number],
            default: "0",
        },
        max: {
            type: [String, Number],
            default: "100",
        },
    },
    data() {
        return {
            range: 0,
        };
    },
    computed: {
        thumbPosition() {
            return `${(Number(this.range) / Number(this.max)) * 100}%`;
        },
    },
    watch: {
        range(value) {
            this.$emit("input", value);
        },
    },
};
</script>

<style scoped lang="scss">
.range {
    &__thumb {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #0084f4;
        box-shadow: inset 0px 0px 20px rgba(221, 221, 221, 0.2);
        border-radius: 4px;
        color: #0084f4;
        left: var(--thumb-position);
        transform: translate(-40%, -2px);
        pointer-events: none;
        top: -5px;
    }
    &__slider {
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 4px;
        border-radius: 10px;
        background: #f0f1f3;
        outline: none;
        border: none;

        &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -webkit-transition: 0.3s all ease-out;
            width: 40px;
            height: 40px;
            left: var(--thumb-position);
            transform: translateY(-2px);
            opacity: 0;
            cursor: pointer;
        }
    }
}
</style>
