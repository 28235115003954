<template>
    <button
        class="button flex flex__center flex__item-center cursor-pointer"
        :class="{ active }"
        v-on="$listeners"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "ButtonSelect",
    props: {
        active: {
            default: false,
            type: Boolean,
        },
    },
};
</script>

<style scoped lang="scss">
.button {
    background: #fff;
    border: 1px solid #a1a4b1;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    white-space: nowrap;
    color: #8b90a0;
    height: 52px;
    transition: 0.3s all ease-out;

    &.active {
        background: rgba(90, 170, 223, 0.04);
        color: #0084f4;
        border: 1px solid #0084f4;
    }
}
</style>
