<template>
    <div class="filters" :class="{ 'no-animation': onMobileView }">
        <div class="flex flex__spaced flex__item-center mb-4">
            <p class="fs-32 fw-600">Filters</p>
            <span class="text-underline text-blue fs-16">
                4 Filters applied
            </span>
        </div>

        <Accordion title="Industry" :wasOpen="true" class="mb-2">
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="ajkcac" />
                <label for="ajkcac" class="fw-600" style="color: #8b90a0"
                    >Industry A</label
                >
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="bsdfsf" />
                <label for="bsdfsf" class="fw-600" style="color: #8b90a0"
                    >Industry B</label
                >
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="dfbss" />
                <label for="dfbss" class="fw-600" style="color: #8b90a0"
                    >Industry C</label
                >
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="dsfvfb" />
                <label for="dsfvfb" class="fw-600" style="color: #8b90a0"
                    >Industry D</label
                >
            </div>
            <p class="text-underline text-blue fs-16">+20 more</p>
        </Accordion>

        <Accordion title="Salary" :wasOpen="true" class="mb-2">
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="esdvsdv" />
                <label class="fw-600" for="esdvsdv" style="color: #8b90a0">
                    0-2 Lakh ₦ (200)
                </label>
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="sjkdbs" />
                <label for="sjkdbs" class="fw-600" style="color: #8b90a0">
                    2-5 Lakh ₦(96)
                </label>
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="jksbfdv" />
                <label for="jksbfdv" class="fw-600" style="color: #8b90a0">
                    5-8 Lakh ₦(15)
                </label>
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="uouief" />
                <label for="uouief" class="fw-600" style="color: #8b90a0">
                    8-12 Lakh ₦ (12)
                </label>
            </div>
            <p class="text-underline text-blue fs-16">+ 5 more</p>
        </Accordion>

        <Accordion title="Experience" :wasOpen="true" class="mb-2">
            <Range
                startText="Fresh"
                endText="30+ years"
                min="0"
                max="30"
                v-model="experience"
            />
        </Accordion>

        <Accordion title="Location" :wasOpen="true" class="mb-2">
            <div class="flex mb-3">
                <div class="mr-3">
                    <Radio
                        radio-value="Distance"
                        name="location"
                        id="distance"
                        v-model="location"
                        label="Distance"
                    />
                </div>
                <div>
                    <Radio
                        radio-value="City"
                        name="location"
                        id="city"
                        v-model="location"
                        label="City"
                    />
                </div>
            </div>
            <Range
                startText="1 KM"
                endText="1000 KM"
                min="1"
                max="1000"
                v-model="location"
            />
        </Accordion>

        <Accordion title="Time Posted" :wasOpen="true" class="mb-2">
            <Range
                startText="Today"
                endText="60 Days Ago"
                min="0"
                max="60"
                v-model="daysAgo"
            />
        </Accordion>

        <Accordion title="Company Size" :wasOpen="true" class="mb-2">
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="lsjdvnks" />
                <label class="fw-600" for="lsjdvnks" style="color: #8b90a0">
                    5-50 Employees
                </label>
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="sdknvskldv" />
                <label for="sdknvskldv" class="fw-600" style="color: #8b90a0">
                    50-80 Employees
                </label>
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="njksdbfksd" />
                <label for="njksdbfksd" class="fw-600" style="color: #8b90a0">
                    80-120 Employees
                </label>
            </div>
            <div class="flex flex__item-center mb-3">
                <Checkbox v-model="checked" class="mr-1" id="sdkjbsdvc" />
                <label for="sdkjbsdvc" class="fw-600" style="color: #8b90a0">
                    120-400 Employees
                </label>
            </div>
            <p class="text-underline text-blue fs-16">+5 more</p>
        </Accordion>

        <Accordion title="Job Type" :wasOpen="true" class="mb-2">
            <div class="grid grid__layout">
                <div class="col-6">
                    <ButtonSelect
                        @click="jobType = 'Full Time'"
                        :active="jobType === 'Full Time'"
                    >
                        Full Time
                    </ButtonSelect>
                </div>
                <div class="col-6">
                    <ButtonSelect
                        @click="jobType = 'Part Time'"
                        :active="jobType === 'Part Time'"
                    >
                        Part Time
                    </ButtonSelect>
                </div>
                <div class="col-6">
                    <ButtonSelect
                        @click="jobType = 'Contract'"
                        :active="jobType === 'Contract'"
                    >
                        Contract
                    </ButtonSelect>
                </div>
            </div>
        </Accordion>

        <div class="flex flex__item-center mb-3">
            <Checkbox v-model="onlyFeaturedJobs" class="mr-1" id="sjkbdks" />
            <label for="sjkbdks" class="fw-600 fs-16 text-blue">
                Only featured jobs
            </label>
        </div>
        <Button theme="blue" @click="$emit('apply-filters')">
            Apply Filters
        </Button>
    </div>
</template>

<script>
import Accordion from "../../../../components/Accordion";
import Checkbox from "../../../../components/Checkbox";
import Range from "../../../../components/Range";
import Radio from "../../../../components/Radio";
import ButtonSelect from "../../../../components/ButtonSelect";
import MediaQuery from "@/mixins/mediaQuery";

export default {
    name: "Filters",
    mixins: [MediaQuery],
    components: {
        Accordion,
        Checkbox,
        Range,
        Radio,
        ButtonSelect,
        MediaQuery,
    },
    data() {
        return {
            checked: [],
            experience: "",
            location: "",
            daysAgo: "",
            onlyFeaturedJobs: false,
            jobType: "",
        };
    },
};
</script>

<style scoped lang="scss">
@import "./filters.scss";
</style>
