<template>
    <div class="flex__grow jobs" :class="{ 'no-animation': onMobileView }">
        <FilterHeader class="mb-6 m-d-none" />

        <div class="px-6" :class="{ 'no-padding': onMobileView }">
            <div
                class="flex flex__spaced flex__item-center mb-4"
                v-if="!onMobileView"
            >
                <p class="text__grey fw-600 fs-16">
                    1-10 of 12,000 “Developer” Jobs
                </p>
                <Dropdown
                    placeholder="Sort By:"
                    :options="sortOptions"
                    @selected="sortValue = $event.name"
                    class="w-12"
                    icon="secondary"
                    textColor="#4E4E5A"
                />
            </div>
            <div v-else class="flex flex-end flex__item-center mb-4">
                <Button
                    @click="$emit('toggleFilterMenu')"
                    class="filter-btn w-10 h-4"
                >
                    <span>Filters </span>
                    <div class="w-2 flex">
                        <img
                            style="width: 100%"
                            src="../../../../assets/icons/filter-icon.svg"
                            alt="filter-icon"
                        />
                    </div>
                </Button>
                <Dropdown
                    placeholder="Sort By: "
                    :options="sortOptions"
                    @selected="sortValue = $event.name"
                    class="w-10 h-4 ml-1"
                    :border="true"
                    icon="secondary"
                    textColor="#4E4E5A"
                    type="sm"
                />
            </div>
            <info-card
                style="border-radius: 4px"
                v-for="n in 7"
                :key="n"
                class="mb-5"
                :hover="true"
                @click="
                    $router.push({
                        name: 'job',
                        params: { profession: 'Front-end Developer' },
                    })
                "
            >
                <div class="flex m-flex__column gap-1">
                    <div class="w-7">
                        <img
                            style="width: 100%"
                            src="../../../../assets/icons/google-logo.svg"
                            alt="google-logo"
                        />
                    </div>
                    <div class="flex__grow flex flex__column flex__spaced">
                        <div
                            class="flex flex__spaced m-flex__wrap m-flex__row-gap-1 flex__item-center"
                        >
                            <span class="fs-18 fw-600">
                                Front-end Developer
                            </span>
                            <span class="fs-14 fw-400">
                                Google India Private Limited
                            </span>
                            <div class="flex flex__item-center">
                                <div class="flex">
                                    <img
                                        src="../../../../assets/icons/clock-icon.svg"
                                        alt="clock-icon"
                                    />
                                </div>
                                &nbsp;
                                <span class="text__grey fw-400 fs-14">
                                    2 days Ago
                                </span>
                            </div>
                            <p><Tag background="blue" text="Part Time" /></p>
                        </div>
                        <div
                            class="flex flex__spaced m-flex__column m-flex__row-gap-1 flex__item-center"
                        >
                            <span
                                class="skills__tab m-flex__align-self-start"
                                :class="{ 'mt-1': onMobileView }"
                                >Web Development</span
                            >
                            <div class="flex m-flex__wrap m-flex__row-gap-1">
                                <div class="flex mr-2">
                                    <div class="flex">
                                        <img
                                            src="../../../../assets/icons/cert-icon.svg"
                                            alt="cert-icon"
                                        />
                                    </div>
                                    &nbsp;
                                    <span class="text__grey fw-400 fs-14">
                                        2 - 5 Years
                                    </span>
                                </div>
                                <div class="flex mr-2">
                                    <div class="flex">
                                        <img
                                            src="../../../../assets/icons/naira-icon.svg"
                                            alt="naira-icon"
                                        />
                                    </div>
                                    &nbsp;
                                    <span class="text__grey fw-400 fs-14">
                                        2000 - 6000
                                    </span>
                                </div>
                                <div class="flex">
                                    <div class="flex">
                                        <img
                                            src="../../../../assets/icons/pin-icon.svg"
                                            alt="pin-icon"
                                        />
                                    </div>
                                    &nbsp;
                                    <span class="text__grey fw-400 fs-14">
                                        Ibadan & Oyo State
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="[2, 5].includes(n)"
                        class="u-absolute jobs__featured"
                    >
                        <Tag text="Featured" background="purple" />
                    </div>
                </div>
            </info-card>

            <div class="flex flex__center m-flex-end">
                <Pagination
                    @paginate="handlePagination"
                    :currentPage="currentPage"
                    :perPage="10"
                    :total="120"
                />
            </div>
        </div>
    </div>
</template>

<script>
import FilterHeader from "./components/FilterHeader";
import InfoCard from "@/components/InfoCard";
import Dropdown from "@/components/Dropdown";
import Tag from "@/components/Tag";
import MediaQuery from "@/mixins/mediaQuery";
import Pagination from "@/components/Pagination";

export default {
    name: "JobsView",
    mixins: [MediaQuery],
    components: {
        FilterHeader,
        InfoCard,
        Dropdown,
        Tag,
        Pagination,
    },
    data() {
        return {
            sortOptions: [
                {
                    name: "Relevant",
                    label: "relevant",
                },
                {
                    name: "Hottest",
                    label: "hottest",
                },
            ],
            sortValue: "",
            currentPage: 1,
        };
    },
    methods: {
        handlePagination(page) {
            this.currentPage = page;
        },
    },
};
</script>

<style scoped lang="scss">
@import "./jobs.scss";
</style>
