import { mapGetters } from "vuex";

export default {
    name: "Pagination",
    props: {
        currentPage: {
            required: true,
        },
        perPage: {
            required: true,
        },
        total: {
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selectedCurrentPage: "",
            inputError: false,
            page: this.currentPage,
        };
    },
    computed: {
        pages() {
            // modified from: https://stackoverflow.com/questions/55585987/pagination-algorithm-with-ellipsis
            var delta = 2,
                range = [],
                rangeWithDots = [],
                nrOfPages = Math.ceil(this.total / Number(this.perPage)),
                len;

            range.push(1);
            if (nrOfPages <= 1) {
                return range;
            }
            for (
                let i = Number(this.currentPage) - delta;
                i <= Number(this.currentPage) + delta;
                i++
            ) {
                if (i < nrOfPages && i > 1) {
                    range.push(i);
                }
            }
            range.push(nrOfPages);
            for (let i of range) {
                if (len) {
                    if (i - len === 2) {
                        rangeWithDots.push(len + 1);
                    } else if (i - len !== 1) {
                        rangeWithDots.push("...");
                    }
                }
                rangeWithDots.push(i);
                len = i;
            }
            return rangeWithDots;
        },
        ...mapGetters({
            status: "getStatus",
        }),
    },
    watch: {
        page(data) {
            this.$emit("paginate", data);
        },
    },
    methods: {
        setPage(page) {
            if (Number(page)) {
                this.page = page;
            }
        },
    },
};
