<template>
    <div class="search__box u-relative">
        <input
            class="search__input"
            :class="[type ? type : '']"
            type="text"
            v-model="searchValue"
            name="text"
            :placeholder="placeholder"
        />
        <img
            class="u-absolute search__icon"
            src="../../assets/icons/search-icon.svg"
            alt="search-icon"
        />
    </div>
</template>

<script>
export default {
    name: "SearchInput",
    props: {
        placeholder: {
            type: String,
            default: "Search...",
        },
        delay: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            searchValue: "",
            timeout: "",
        };
    },
    watch: {
        searchValue(value) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$emit("input", value);
            }, this.delay);
        },
    },
};
</script>

<style scoped lang="scss">
@use "./searchInput.scss" as *;
</style>
