<template>
    <PageLayout :headerWithBG="true">
        <div class="flex">
            <template>
                <Filters
                    @apply-filters="applyFilters"
                    v-if="!this.onMobileView"
                />
                <MobileMenu
                    v-else
                    :active="showMobileMenu"
                    @close="showMobileMenu = !showMobileMenu"
                    :fullHeight="true"
                >
                    <Filters @apply-filters="applyFilters" />
                </MobileMenu>
            </template>
            <JobsView @toggleFilterMenu="showMobileMenu = !showMobileMenu" />
        </div>
    </PageLayout>
</template>

<script>
import PageLayout from "../../layouts/PageLayout";
import Filters from "./components/Filters";
import JobsView from "./components/JobsView";
import MobileMenu from "@/components/MobileMenu";
import MediaQuery from "@/mixins/mediaQuery";

export default {
    name: "Jobs",
    mixins: [MediaQuery],
    components: {
        PageLayout,
        Filters,
        JobsView,
        MobileMenu,
    },
    data() {
        return {
            showMobileMenu: false,
        };
    },
    methods: {
        applyFilters() {
            if (this.onMobileView) {
                this.showMobileMenu = !this.showMobileMenu;
            }
        },
    },
};
</script>

<style scoped></style>
