import "../../utils/veeValidateRules";
import { ValidationProvider } from "vee-validate";

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        radioValue: {
            required: true,
            type: String,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
    },
};
